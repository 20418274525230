<template>
  <div class="wrap">
    <div class="type" v-if="step === 1">
      <div class="title">
        <img :src="require('@/assets/images/auth.png')" alt="">
        <span>绑定店铺</span>
      </div>
      <div class="form">
<!--        <van-field
            v-model="account"
            label=""
            placeholder="手机号/用户名"
        >
          <template #left-icon>
            <van-icon :name="require('@/assets/images/user.svg')" />
          </template>
        </van-field>
        <van-field
            v-model="password"
            label=""
            type="password"
            placeholder="密码"
        >
          <template #left-icon>
            <van-icon :name="require('@/assets/images/lock.svg')" />
          </template>
        </van-field>-->
        <van-field
            v-model="authCode"
            label=""
            placeholder="授权码"
        >
          <template #left-icon>
            <van-icon size="22px" :name="require('@/assets/images/auth.png')" />
          </template>
        </van-field>
        <van-button class="bind-btn" color="#eca04f" type="primary" @click="bindAccount" :loading="btnLoading" loading-type="spinner">绑定</van-button>
      </div>
      <div class="tips">
        请填写（<span>MinoTrend For MercadoLibre</span>）的授权码，完成绑定
      </div>
    </div>

    <div class="type" v-if="step === 2">
      <div class="logo-wrap">
        <img :src="require('@/assets/images/bind-success-logo.png')" alt="">
      </div>
      <div class="message">
        <template v-if="errorCode === 0">
          绑定成功
        </template>
        <template v-else-if="errorCode === 201">
          授权码无效，绑定失败
        </template>
        <template v-else-if="errorCode === 301">
          抱歉，您的店铺VIP特权已到期
        </template>
        <template v-else-if="errorCode === 202">
          绑定失败
        </template>
        <template v-else-if="errorCode === 203">
          您的微信号需要二次验证才能完成绑定，请联系管理员处理
        </template>
        <template v-else>
          {{ errorMessage ? errorMessage : '系统错误' }}
        </template>
      </div>
      <van-button class="back-btn" color="#7295de" type="primary" @click="toIndexPage">返回首页</van-button>
      <div class="other">
        还有其他账号？<span @click="reset">继续绑定</span>
      </div>
      <div class="detail">
        <template v-if="errorCode === 0">
          您需要先关注微信公众号「<span>MinoTrend</span>」，才能收到实时消息通知
        </template>
        <template v-else-if="errorCode === 201">
          请联系<span>MinoTrend For MercadoLibre</span>管理员更新授权码
        </template>
        <template v-else-if="errorCode === 301">
          请前往<span>MinoTrend For MercadoLibre</span>查看服务详情
        </template>
        <template v-else-if="errorCode === 202">
          您的二次验证已被管理员拒绝，请使用新的授权码绑定
        </template>
        <template v-else-if="errorCode === 203">
          您的微信号需要二次验证才能完成绑定，请联系管理员处理
        </template>
        <template v-else>
          <span style="text-align: center;">系统错误，请稍后再试</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {bind} from "@/api/bindData";
import request from "@/utils/request";
import store from "@/store";
import {GET_USER_INFO} from "@/store/mutation-types";

export default {
  data() {
    return {
      step: 1,
      authCode: '',
      btnLoading: false,
      errorCode: 0,
      errorMessage: '',
    }
  },
  created() {
    this.getAuthStatus();
  },
  methods: {
    getAuthStatus() {
      request({
        url: '/auth/status',
      })
        .then(res => {
          if (res.error === 0) {
            if (res.data.checkStatus === -1) {
              this.step = 2;
              this.errorCode = 202;
              this.setAuthRead();
            }
          } else {
            console.log('获取验证状态错误', res.message);
          }
        })
        .catch(error => {
          console.log('获取验证状态错误', error);
        });
    },
    setAuthRead() {
      request({
        url: 'auth/status/read',
        method: 'put',
      })
        .then(res => {
          if (res.error === 0) {
            console.log('设置已读状态成功');
          } else {
            console.log('设置验证状态已读错误', res.message);
          }
        })
        .catch(error => {
          console.log('设置验证状态已读错误', error);
        })
    },
    bindAccount() {
      if (!this.authCode) {
        this.$message('请填写授权码');
        return false;
      }

      this.btnLoading = true;
      bind(this.authCode)
        .then(res => {
          this.btnLoading = false;
          this.step = 2;
          this.errorCode = res.error;
          this.errorMessage = res.message ? res.message : '';

          if (res.error === 0) {
            this.$store.dispatch(GET_USER_INFO)
            .then(() => {

            })
            .catch(() => {

            })
          }
        })
        .catch(error => {
          this.btnLoading = false;
          console.log('绑定错误', error);
        });
    },

    toIndexPage() {
      this.$router.push({
        name: 'index',
      })
    },

    reset() {
      this.step = 1;
      this.errorCode = 0;
      this.authCode = '';
    }
  }
}
</script>

<style lang="less" scoped>
  .wrap{
    background-image: url("../../assets/images/bind-back.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
  }
  .type{
    width: 100%;
    height: 100%;
    .title{
      padding: 3rem 0;
      font-size: 1.4rem;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
    .form{
      width: 80%;
      margin: 0 auto;
      padding: 1rem 1.2rem;
      background-color: #ffffff;
      border-radius: .3rem;
      box-shadow: 5px 5px 5px #777777;
      border: 1px solid #fefefe;
      box-sizing: border-box;
      .bind-btn{
        margin: .7rem 0 0;
        padding: 0 42px;
        border-radius: .5rem;
        color: #ffffff;
        height: 38px;
      }
    }
    .tips{
      width: 80%;
      margin: 1rem auto 0;
      text-align: left;
      font-size: .9rem;
      color: #000000;
      span{
        color: #ff0000;
        font-weight: bold;
      }
    }

    .logo-wrap{
      width: 35%;
      margin:0 auto;
      border-radius: 50%;
      padding: 4.5rem 0 0;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .message{
      font-size: 1.4rem;
      font-weight: bold;
      padding: 1rem 0;
    }

    .back-btn{
      padding: 0 42px;
      border-radius: .5rem;
      color: #ffffff;
      height: 38px;
    }
    .other{
      padding: 3rem 0 1.6rem;
      font-size: .9rem;
      span{
        color:#ff0000;
      }
    }
    .detail{
      width: 80%;
      margin: 0 auto;
      text-align: left;
      font-size: .9rem;
      span{
        color:#ff0000;
        font-weight: bold;
      }
    }
  }

</style>

<style lang="less">
.form{
  .van-field{
    border: 1px solid #dddddd;
    border-radius: .3rem;
    margin: 0 0 .8rem;
    .van-field__left-icon{
      margin-right: 12px;
    }
  }
}
</style>
