<template>
  <div class="wrap">
<!--    <div class="header">
      售后消息
    </div>-->
    <div class="loading" v-show="loading">
      <van-loading />
    </div>
    <div class="content-wrap-box" :class="{'show-uploader': uploaderShow}" ref="box" v-show="!loading">
      <div class="content-wrap">
        <div class="order">
          <div class="other">
            <div class="name">{{ message.customerName }}</div>
            <div class="right">
              <div>
                <span class="order-id">#{{ message.orderId }},</span> <span class="date">{{ message.orderTime }}</span>
              </div>
              <div class="shipping-info" v-if="message.shippingId">
                <span class="shipping-id">{{ message.shippingId }}, </span> <span class="shipping-status">{{ message.shippingInfo ? message.shippingInfo.shippingStatusText : '-' }}</span>
              </div>
            </div>
          </div>
          <div class="order-list">
            <div class="item" v-for="order in message.orders" :key="order.orderId">
              <div class="goods-list">
                <div class="goods" v-for="goods in order.goods" :key="goods.goodsId">
                  <div class="image" v-is-catalog="goods.catalogProductId" catalog-img-width="2rem" @click="gotoGoodsLink(goods)">
                    <img :src="goods.image" alt="">
                  </div>
                  <div class="info">
                    <div class="title">{{ goods.attrName ? goods.attrName : goods.title }}</div>
                    <div class="id">
                      <span>#{{ goods.goodsId }}</span>
<!--                      <span class="space" v-if="goods.attrName">|</span>-->
<!--                      <span class="attr-name" v-if="goods.attrName">{{ goods.attrName }}</span>-->
                    </div>
                    <div class="num">
                      数量：{{ goods.num }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="amount-wrap" v-show="message.kinds > 1">
                <div class="line">售：${{ order.totalAmount }}</div>
                <div class="line">结：${{ order.settleAmount }}</div>
              </div>
            </div>
          </div>
          <div class="amount-data">
            <div class="amount">销售金额：$ {{ message.totalAmount }}</div>
            <div class="amount">结算金额：$ {{ message.settleAmount }}</div>
          </div>
        </div>
        <div class="chat-list">
          <div class="item" v-for="item in chatList" :key="item.messageId" :class="{item1: item.type === 2, item2: item.type === 1}">
            <div class="avatar" v-if="item.type === 2">{{ message.customerNameAbbr }}</div>
            <div class="avatar" v-if="item.type === 1">
              <van-icon class="wx-avatar" :name="userInfo.avatar" />
            </div>
            <div class="info">
              <div class="box first" v-if="item.text && item.text !== '\n'">
                <div class="text" v-html="item.textFormat"></div>
              </div>
              <div class="box" v-if="item.files.length">
                <div class="image-wrap" v-for="file in item.files" :key="file.file" >
                  <img @click="showImage(file.file)" :src="file.file" v-if="['jpg', 'png', 'gif'].indexOf(file.file.substr(-3)) >= 0 || ['jpeg', 'webp'].indexOf(file.file.substr(-4)) >= 0" alt="" >
                  <a v-else target="_blank" :href="file.file">
                    <van-icon  class="file" :name="require('@/assets/images/file.svg')" />
                  </a>
                </div>
              </div>
              <div class="env" v-if="item.type === 1">
                {{ item.answerEnvText }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-if="!message.isBlockedBySeller">
      <div class="uploader-wrap" v-show="!loading && uploaderShow">
        <van-uploader class="uploader" v-model="fileList" :after-read="upload" ref="uploader" :max-size="25 * 1024 * 1024" preview-size="3.5rem" />
      </div>
      <div class="send-area-wrap">
        <div class="send-area" v-show="!loading">
          <div class="input">
            <van-field
                class="vant-textarea-hack"
                v-model="text"
                :rows="1"
                :border="true"
                type="textarea"
                :autosize="{maxHeight: 120, minHeight: 24,}"
                :placeholder="message.isBlocked ? message.blockedText : ''"
                :disabled="message.isBlocked"
                @focus="onAnswerFocus"
                @blur="onAnswerBlur"
            />
          </div>
          <div class="template-select" @click="openPicker()" :class="{disabled: message.isBlocked}" >
            <img :src="require('@/assets/images/select-template.png')" alt="select">
          </div>
          <div class="attachment"
               :class="{'disabled': message.isBlocked}"
          >
            <van-icon name="photo-o" @click="switchUploader"/>
          </div>
          <van-button class="button" type="primary" @click="send" :disabled="sendDisabled || message.isBlocked">发送</van-button>
        </div>
        <div class="space"></div>
      </div>
    </template>

    <template v-if="message.isBlockedBySeller">
      <div class="guide-options-wrap">
        <div class="guide-options">
          <div class="tips">在买家回复前，您必须选择一种消息类型以继续发送新消息</div>
          <div class="guide-templates">
            <div
                class="option"
                v-for="v in allowGuideTemplates"
                :key="v.optionId"
                :class="{'current': v.optionId === currentTemplate.optionId}"
                @click="guideSubmitData.optionId = v.optionId;guideSubmitData.text='';"
            >
              {{ v.name }} ({{ v.isAutoText ? '可编辑' : '不可编辑' }})
            </div>
          </div>
        </div>
<!--        <div class="close">
          <van-icon name="cross" />
        </div>-->
      </div>
      <div class="send-area-wrap">
        <div class="send-area guide-wrap" v-show="!loading">
          <div class="input">
            <van-field
                class="vant-textarea-hack"
                v-model="guideSubmitData.text"
                :rows="1"
                :border="true"
                type="textarea"
                :autosize="{maxHeight: 120, minHeight: 24,}"
                :placeholder="currentTemplate.isAutoText ? '' : currentTemplate.placeholderText"
                :disabled="!currentTemplate.isAutoText"
                @focus.native="onAnswerFocus"
                @blur.native="onAnswerBlur"
            />
          </div>
          <div class="template-select" @click="openPicker()" v-show="currentTemplate.isAutoText">
            <img :src="require('@/assets/images/select-template.png')" alt="select">
          </div>
          <van-button class="button" type="primary" @click="sendGuideMessage" :disabled="sendDisabled">发送</van-button>
        </div>
        <div class="space"></div>
      </div>

    </template>
    <van-popup v-model="showQuestionTemplate" position="bottom" :style="{ height: '50%' }">
      <van-picker
          title="请选择对应的模板"
          show-toolbar
          :columns="allTemplatesTitles"
          @confirm="onTemplatesConfirm"
          @cancel="showQuestionTemplate = false"
      />
    </van-popup>

  </div>
</template>

<script>
import request from "@/utils/request";
import {ImagePreview, Loading} from "vant";

let timer = null;
export default {
  beforeRouteLeave(to, from, next) {
    if (timer) {
      clearInterval(timer);
    }
    next();
  },
  components: {

  },
  data() {
    return {
      loading: false,
      uploaderShow: false,
      fileList: [],
      chatList: [],
      message: {},
      text: '',
      sendDisabled: false,
      allGuideTemplates: [],
      guideSubmitData: {
        optionId: '',
        text: '',
      },
      showQuestionTemplate: false,
      allTemplates: [],
      selectTemplate: null,
      // 是否显示回复输入框的红字
      showTemplateTips: false,
    }
  },
  computed: {
    // 用户信息
    userInfo: function () {
      return this.$store.state.userInfo;
    },
    // 当前信息能选择的模板
    allowGuideTemplates: function () {
      let allowGuideTemplates = this.allGuideTemplates;
      if (this.message && this.message.guidesOptionIds) {
        allowGuideTemplates = allowGuideTemplates.filter(v => this.message.guidesOptionIds.indexOf(v.optionId) >= 0);
      }
      return allowGuideTemplates;
    },
    // 当前选择的模板
    currentTemplate: function () {
      let currentTemplate = {};
      if (this.guideSubmitData.optionId) {
        currentTemplate = this.allGuideTemplates.find(v => v.optionId === this.guideSubmitData.optionId);
      }
      currentTemplate = currentTemplate ? currentTemplate : {};
      return currentTemplate;
    },
    // 全部标题
    allTemplatesTitles: function () {
      const allTemplatesTitles = [];
      this.allTemplates.forEach(item => {
        allTemplatesTitles.push(item.title);
      })
      return allTemplatesTitles;
    },
    // 复合文字
    complexText: {
      get: function () {
        if (!this.message.isBlockedBySeller) {
          return this.text;
        } else {
          return this.guideSubmitData.text;
        }
      },
      set: function (value) {
        if (!this.message.isBlockedBySeller) {
          this.text = value;
        } else {
          this.guideSubmitData.text = value;
        }
      },
    },
  },
  created() {
    this.loading = true;
    this.getData(true);
    this.loopQueryData();
  },
  methods: {
    getData(down = false) {
      request({
        url: `/message/${this.$route.params.orderId}/${this.$route.params.oUserId}`,
      })
        .then(res => {
          this.loading = false;
          if (res.error === 0) {
            let update = false;
            if (this.chatList.length) {
              const oldLastTime = Date.parse(this.chatList[this.chatList.length - 1]['messageDate']);
              const lastTime = Date.parse(res.data[res.data.length - 1]['messageDate']);
              update = lastTime > oldLastTime;
            }
            const chatList = [];
            res.data.forEach(item => {
              item.textFormat = item.text.replace(/\n/g, '<br>');
              chatList.push(item);
            })
            this.chatList = chatList;
            this.message = res.info.message;

            const allGuideTemplates = res.info.allGuideTemplates;
            for (let i = 0; i < allGuideTemplates.length; i++) {
              if (allGuideTemplates[i].isAutoText) {
                allGuideTemplates[i]['placeholderText'] = '可自由编辑，最多350个字符';
              } else {
                let placeholderText = '不可编辑，将发送以下原文（仅西班牙语部分）';
                placeholderText += "\n\n";
                placeholderText += allGuideTemplates[i].esText;
                placeholderText += "\n\n";
                placeholderText += allGuideTemplates[i].zhText;
                allGuideTemplates[i]['placeholderText'] = placeholderText;
              }
            }
            this.allGuideTemplates = allGuideTemplates;
            if (down && this.allGuideTemplates.length) {
              this.guideSubmitData.optionId = this.allGuideTemplates[0].optionId;
            }

            if (update || down) {
              setTimeout(() => {
                this.$refs['box'].scrollTop = this.$refs['box'].scrollHeight;
              }, 600);
            }
            if (down) {
              this.allTemplates = res.info.templates;
            }
          } else {
            this.$router.push({
              name: 'index',
            });
            if (timer) {
              clearInterval(timer);
            }
            this.$errorMessage(res.message);
          }
        })
        .catch(error => {
          this.loading = false;
          console.log('请求聊天列表错误', error);
          this.$errorMessage('网络错误，请稍后再试');
        })
    },
    // 上传
    upload(file) {
      file.status = 'uploading';
      file.message = '上传中...';

      const data = new FormData();
      data.append('file', file.file);

      request({
        url: `/message/${this.$route.params.orderId}/${this.$route.params.oUserId}/attachments`,
        method: 'post',
        data,
        config: {
          headers:{'Content-Type':'multipart/form-data'}
        },
      })
        .then(res => {
          if (res.error === 0) {
            file.url = res.info.imageUrl;
            file.imageId = res.info.imageId;
            file.status = 'done';
            file.message = '上传完成';
          } else {
            file.status = 'failed';
            file.message = '上传失败';
            this.$errorMessage(res.message);
          }
          this.clearFailedFiles();
        })
        .catch(error => {
          file.status = 'failed';
          file.message = '上传失败';
          console.log('上传文件失败', error);
          this.$errorMessage('网络错误');
          this.clearFailedFiles();
        })
    },
    // 切换上传界面
    switchUploader() {
      if (this.message.isBlocked && !this.message.isBlockedBySeller) {
        return false;
      }
      if (!this.uploaderShow) {
        this.uploaderShow = true;
        if (!this.fileList.length) {
          this.$refs['uploader'].chooseFile();
        }
      } else {
        this.uploaderShow = false;
      }
    },
    // 清除上传失败的文件
    clearFailedFiles() {
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].status === 'failed') {
          this.fileList.splice(i, 1);
          break;
        }
      }
    },
    // 发送信息
    send() {
      // console.log( 'this.fileList', this.fileList);

      const attachments = [];
      this.fileList.forEach(item => {
        if (item.imageId) {
          attachments.push(item.imageId);
        }
      });

      if (!this.text && !attachments.length) {
        this.$message('请填写信息或上传附件');
        return false;
      }

      this.sendDisabled = true;
      request({
        url: `/message/${this.$route.params.orderId}/${this.$route.params.oUserId}/send`,
        method: 'post',
        data: {
          text: this.text,
          attachments: attachments,
        }
      })
        .then(res => {
          this.sendDisabled = false;
          if (res.error === 0) {
            if (this.fileList.length) {
              this.fileList = [];
            }
            this.uploaderShow = false;
            this.getData(true);
            this.text = '';
          } else {
            this.$errorMessage(res.message)
          }
        })
        .catch(error => {
          this.sendDisabled = false;
          this.$errorMessage('网络错误');
          console.log('发送信息错误', error);
        })
    },
    // 发送信息
    sendGuideMessage() {
      this.sendDisabled = true;
      request({
        url: `/message/guide/${this.$route.params.orderId}/${this.$route.params.oUserId}/send`,
        method: 'post',
        data: this.guideSubmitData,
      })
          .then(res => {
            this.sendDisabled = false;
            if (res.error === 0) {
              if (this.fileList.length) {
                this.fileList = [];
              }
              this.uploaderShow = false;
              this.getData(true);
              this.text = '';
            } else {
              this.$errorMessage(res.message)
            }
          })
          .catch(error => {
            this.sendDisabled = false;
            this.$errorMessage('网络错误');
            console.log('发送信息错误', error);
          })
    },
    // 显示图片
    showImage(imageUrl) {
      ImagePreview([imageUrl]);
    },
    // 轮询服务器是否有新的信息，暂定5秒执行一次
    loopQueryData() {
      if (timer) {
        clearInterval(timer);
      }

      timer = setInterval(() => {
        console.log('message_loop');
        if (this.$route.params.orderId && this.$route.params.oUserId) {
          this.getData();
        }
      }, 5000);
    },
    // 打开选择面板
    openPicker() {
      if (!this.message.isBlockedBySeller && this.message.isBlocked) {
        return false;
      }
      this.showQuestionTemplate = true;
    },
    // 选中特定模板
    onTemplatesConfirm(value, index) {
      this.showQuestionTemplate = false;
      if (this.allTemplates[index]) {
        this.selectTemplate = this.allTemplates[index];
        this.complexText = `${this.selectTemplate.content}`
        this.showTemplateTips = false;
      }
    },

    // 输入框获焦点时
    onAnswerFocus() {
      this.showTemplateTips = false;
      if (this.selectTemplate) {
        this.complexText = `${this.selectTemplate.content}`
      }
    },

    // 输入框失去焦点时
    onAnswerBlur() {
      if (this.selectTemplate) {
        this.showTemplateTips = false;
        if (this.complexText === this.selectTemplate.content) {
          this.complexText = `${this.selectTemplate.content}`
        } else {
          this.selectTemplate = null;
        }
      }
    },
    gotoGoodsLink(goods) {
      if (goods.link) {
        window.open(goods.link, '_blank');
      }
    }
  }
}
</script>

<style lang="less" scoped>
.wrap{
  flex-direction: column;
  height: 100vh;
}
.header{
  font-size: 1.2rem;
  padding: .5rem 0;
  color: #000000;
  background-color: #f2f2f2;
  font-weight: bold;
  height: 1.6rem;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.content-wrap-box{
  padding: 0 0 2.8rem;
  height: 100vh;
  overflow-y: scroll;
  box-sizing: border-box;
  &.show-uploader{
    padding: 2.8rem 0 7.6rem;
  }
}
.content-wrap{
  padding: .6rem;
  display: flex;
  flex-direction: column;
  .order{
    flex: 0 0 auto;
    border: 1px solid #aaaaaa;
    border-radius: .4rem;
    background: #f3f3f3;
    padding: 0 1rem;
    .other{
      display: flex;
      flex-direction: column;
      color: #aaaaaa;
      font-size: .8rem;
      //justify-content: space-between;
      padding: .4rem 0;
      border-bottom: 1px solid #eeeeee;
      align-items: flex-start;
      .right{
        text-align: left;
        .date{
          margin-left: 5px;
        }
        .shipping-status{
          margin-left: 5px;
        }
      }
    }
    .order-list{
      border-top: 1px solid #aaaaaa;
      border-bottom: 1px solid #aaaaaa;
      padding: .5rem 0 0;
      .item{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 0 0 .5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        .goods-list{
          font-size: .8rem;
          .goods{
            display: flex;
            border-bottom: 1px solid #eeeeee;
            &:nth-last-child(1) {
              border-bottom: none;
            }
            .image{
              width: 3.5rem;
              height: 3.5rem;
              flex: 0 0 auto;
              margin-right: .5rem;
              img{
                width: 100%;
                height: 100%;
              }
            }
            .info{
              text-align: left;
              /*overflow: hidden;
              text-overflow: ellipsis;*/
              .title{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                height: 1rem;
                line-height: 1rem;
              }
              .id{
                //padding: .2rem 0;
                .space{
                  margin: 0 3px;
                }
                .attr-name{
                  color: #aaaaaa;
                }
              }
              .num{
                padding: 0 0 0;
              }
            }
          }
        }
        .amount-wrap{
          font-size: .8rem;
          color: #aaaaaa;
          padding-left: 6px;
          .line{
            white-space: nowrap;
            text-align: left;
          }
        }
      }
    }
    .amount-data{
      display: flex;
      justify-content: space-between;
      font-size: .8rem;
      padding: .4rem 0;
      color: #aaaaaa;
    }
  }
  .chat-list{
    padding: .6rem 0 0;
    font-size: .9rem;
    flex: 1 1 auto;
    .item{
      display: flex;
      &.item1{
        flex-direction: row;
        justify-content: flex-start;
        .info{
          padding-left: .8rem;
        }
      }
      &.item2{
        flex-direction: row-reverse;
        .info{
          padding-right: .8rem;
          .box{
            background: #ddeaff;
          }
        }
        .avatar{
          //padding-top: .3rem;
          height: 2.4rem;
          line-height: 2.4rem;
          .wx-avatar{
            width: 100%;
            height: 100%;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .avatar{
        background-color: #d7d7d7;
        border-radius: 50%;
        font-size: 1.3rem;
        flex: 0 0 auto;
        width: 2.4rem;
        height: 2.4rem;
        line-height: 2.4rem;
        text-align: center;
        color: #666666;
        margin: .3rem 0 0;
        overflow: hidden;
      }
      .info{
        text-align: left;
        margin: .3rem 0;
        .box{
          border: none;
          padding: .3rem;
          border-radius: .3rem;
          background: #f3f3f3;
          .image-wrap{
            padding: .3rem 0;
            img{
              width: 3rem;
              height: 3rem;
              display: block;
            }
            a{
              display: block;
            }
          }
          .ext{
            font-size: .8rem;
            color: #aaaaaa;
            padding: .2rem 0 0;
            text-align: left;
          }
        }
        .env{
          font-size: .7rem;
          color: #aaaaaa;
        }
      }
    }
  }
}
.uploader-wrap{
  height: 3.5rem;
  position: fixed;
  bottom: 3.2rem;
  left: 0;
  width: 100%;
  text-align: left;
  background-color: #f2f2f2;
  padding: .5rem .5rem;
  border-bottom: 1px solid #ffffff;
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
  .uploader{

  }
}
.send-area-wrap{
  background-color: #f2f2f2;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  .space{
    height: constant(safe-area-inset-bottom);
    height: env(safe-area-inset-bottom);
  }
}
.send-area{
  display: flex;
  align-items: flex-end;
  padding: .3rem .5rem .6rem;
  //height: 3.2rem;
  font-size: .9rem;
  box-sizing: border-box;

  width: 100%;
  .input{
    width: 100%;
    flex: 1 1 auto;
    line-height: 1.5;
    //padding: 0 4px;
    textarea{
      box-sizing: border-box;
      line-height: 1.5;
      border: none;
      border-radius: .4rem;
      width: 100%;
      padding: 5px 4px;
    }
  }
  .template-select{
    flex: 0 0 auto;
    margin: 0 .4rem;
    width: 1.8rem;
    height: 1.8rem;
    &.disabled{
      opacity: .5;
    }
    img{
      width: 100%;
      height: 100%;
    }
  }
  .attachment{
    flex: 0 0 auto;
    margin: .3rem .5rem 0 .5rem;
    font-size: 2rem;
    line-height: 2.8rem;
    width: 2rem;
    height: 2rem;
    &.disabled{
      opacity: .5;
    }
  }
  .button{
    flex: 0 0 auto;
    width: 5rem;
    margin: .2rem .3rem 0;
    padding: 0 .2rem;
    height: 1.8rem;
  }
}
.space{
  height: constant(safe-area-inset-bottom);
  height: env(safe-area-inset-bottom);
}

.guide-options-wrap{
  position: fixed;
  bottom: 3.2rem;
  left: 0;
  width: 100%;
  padding: 30px 0 20px;
  background-color:rgba(255, 255, 255, 0.8);
  .guide-options{
    .tips{
      font-size: .9rem;
    }
    .guide-templates{
      padding: 20px 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .option{
        margin-bottom: 20px;
        padding: 5px 0;
        width: 90%;
        border: 1px solid #d7d7d7;
        border-radius: 8px;
        cursor: pointer;
        &.current{
          border: 1px solid #d8e7ff;
          background-color: #d8e7ff;
        }
      }
    }
  }
  .close{
    position: absolute;
    right: 20px;
    top: 5px;
  }
}

</style>

<style lang="less">
.input{
  .vant-textarea-hack{
    &.van-field{
      padding: 5px 4px;
      .van-field__label{
        display: none;
      }
    }
  }
}
</style>
