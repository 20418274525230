import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Button, Loading, Notify, Toast, Icon, Field, List, Uploader, Dialog, Cell, CellGroup, Picker, Popup } from 'vant';
import request from "@/utils/request";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

// 全局样式
import '@/styles/base.less';

// 登录控制
import '@/permission';
import {importThirdPart} from "@/utils/chat";
import isCatalog from "./directive/isCatalog";

// 注册vant组件
Vue.use(Button)
  .use(Loading)
  .use(Notify)
  .use(Toast)
  .use(Icon)
  .use(Field)
  .use(List)
  .use(Uploader)
  .use(Dialog)
  .use(Cell)
  .use(CellGroup)
  .use(Picker)
  .use(Popup)

Vue.config.productionTip = false

// 请求封装
Vue.prototype.$request = opt => {
  return request(opt);
}

// 提示信息封装
Vue.prototype.$message = (text, duration = 2000) => {
  Toast({
    message: text,
    duration
  });
}
Vue.prototype.$successMessage = text => {
  Toast.success(text);
}
Vue.prototype.$errorMessage = text => {
  Toast.fail(text);
}

// importThirdPart();

if (process.env.NODE_ENV === 'production') {
  // sentry 设置
  Sentry.init({
    Vue,
    dsn: "https://afebeecb16874691ba35c900581ee3f7@o1174357.ingest.sentry.io/6365164",
    release: process.env.VUE_RELEASE_VERSION,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "my-site-url.com", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  Vue.prototype.$Sentry = Sentry // 在捕获错误的界面需要用到Sentry

  let logError = function () {
    const texts = [];
    const errors = [];
    for (let i = 0; i < arguments.length; i++) {
      const argument = arguments[i];
      if (argument instanceof Error) {
        errors.push(argument);
      } else {
        texts.push(argument.toString());
      }
    }

    let allText = texts.join(', ');
    errors.forEach(error => {
      error.message = allText + '  ' + error.message;
      Sentry.captureException(error);
    });

    return console.error;
  };
  Vue.prototype.$logError = logError;
  // 重写 console.error
  console.error = logError;
}

Vue.use(isCatalog);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
